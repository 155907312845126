<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="6">
        <el-input clearable
                  style="width:100%"
                  placeholder="请输入教师名称或手机号"
                  v-model="keyword"
                  @keyup.enter.native="search"></el-input>
      </el-col>
      <el-col :span="6">
        <!-- <el-input clearable
                  style="width:100%"
                  placeholder="请输入教师名称或手机号"
                  v-model="keyword"
                  @keyup.enter.native="search"></el-input> -->
        <el-select v-model="schoolId"
                   clearable
                   filterable
                   style="width:100%"
                   placeholder="请选择">
          <el-option v-for="item in schoolList"
                     :key="item.school_id"
                     :label="item.school_name"
                     :value="item.school_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-button type="primary"
                   @click="search">确定</el-button>
        <el-button type="primary"
                   @click="tongbu">同步账号</el-button>
        <!-- <el-button type="primary"
                   @click="exportExcel">导出</el-button> -->
        <el-button type="primary"
                   @click="addTeacher">新增教师</el-button>
        <el-button type="primary"
                   @click="importTeacher">导入教师</el-button>
      </el-col>
    </el-row>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />

    <!-- 导入教师弹窗 -->
    <importExcel ref="importExcelRef" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import importExcel from './components/importExcel.vue'
import { getStartSchool } from '@/api/school.js'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      keyword: '',
      tableData: [],
      tableLoading: false,
      showList: [],
      schoolList: [],
      schoolId: undefined,
      pageName: 'teacherAdmin',
      tableColumns: [
        { prop: 'edu_user_id', align: 'center', label: '编号', width: '', showToopic: false },
        { prop: 'edu_user_mobile', align: 'center', label: '手机', width: '130', showToopic: false },
        { prop: 'edu_user_name', align: 'center', label: '用户名', width: '120', showToopic: false },
        { prop: 'edu_user_nickname', align: 'center', label: '用户别名', width: '', showToopic: false },
        { prop: 'school_name', align: 'center', label: '所属学校', width: '', showToopic: false },
        { prop: 'subjects', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'create_time', align: 'center', label: '开通时间', width: '', showToopic: false },
        { prop: 'end_time', align: 'center', label: '截止时间', width: '', showToopic: false },
        { prop: 'is_admin', align: 'center', label: '管理员', width: '', showToopic: false, isStatus: true },
        // { prop: 'is_test_paper', align: 'center', label: '测试试卷', width: '', showToopic: false, isStatus: true }, 
        {
          prop: 'd', align: 'center', label: '操作', width: '150', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'showForm' },
            { content: '删除', type: 'del', event: 'delRow' },
            { content: '重置密码', type: 'reset', event: "resetPwd" }
          ]
        },
      ]
    }
  },
  components: {
    Form, importExcel
  },
  mounted () {
    this.setTablesColums()
    this.getSchoolList()
  },
  methods: {
    getClass (prop, value) {
      // 根据不同字段做处理
      if (prop == 'is_admin') {
        return value ? 'pass' : 'notpass'
      }
      if (prop == 'is_test_paper') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'is_admin') {
        return value ? '是' : '否'
      }
      if (prop == 'is_test_paper') {
        return value ? '是' : '否'
      }
    },
    async getSchoolList () {
      const { data } = await getStartSchool()
      this.schoolList = data.list
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.tableLoading = true
      this.$http({
        url: '/api/v2/bureau/teacher_lst',
        method: 'get',
        params: {
          page,
          limit,
          keyword: this.keyword,
          school_id: this.schoolId
        }
      }).then(res => {
        for (var index = 0; index < res.data.list.length; index++) {
          let row = res.data.list[index]
          row.class
        }
        this.tableData = res.data.list
        this.totalElements = res.data.count
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },

    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/bureau/teacher_del',
          method: 'post',
          data: {
            id: row.edu_user_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    // 重置密码
    resetPwd (row) {
      this.$confirm('此操作将重置密码，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: '/api/v2/bureau/reset_password',
          method: 'post',
          data: {
            edu_user_id: row.edu_user_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '重置成功',
          type: 'success'

        });

      })
    },
    addTeacher () {
      let form = {
        edu_user_mobile: '',
        edu_user_name: '',
        edu_user_nickname: '',
        edu_user_password: '',
        edu_user_password1: '',
        edu_agent_id: '',
        edu_bureau_id: '',
        edu_school_id: '',
        edu_grade_id: '',
        edu_class_id: '',
        subject_id: [],
        end_time: '',
        role: '',
        is_admin: '',
        is_test_paper: '',
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.getList()
      this.$refs.FormRef.dialogVisible = true
    },
    importTeacher () {
      let form = {
        end_time: ''
      }
      this.$refs.importExcelRef.form = form
      this.$refs.importExcelRef.dialogVisible = true
    },
    showForm (row) {
      this.$http({
        url: '/api/v2/bureau/get_teacher_info',
        method: 'get',
        params: {
          id: row.edu_user_id
        }
      }).then(res => {
        let { edu_user_mobile,
          edu_user_name,
          edu_user_nickname,
          edu_agent_id,
          edu_bureau_id,
          edu_school_id,
          edu_grade_id,
          edu_class_id,
          user_class_id,
          subject,
          end_time,
          is_admin,
          is_detailed,
          is_test_paper,
          edu_user_id,
          roles } = res.data.list
        if (subject.length == 0) {
          subject = []
        }
        let form = {
          edu_user_mobile,
          edu_user_name,
          edu_user_nickname,
          edu_agent_id: edu_agent_id == 0 ? '' : edu_agent_id,
          edu_bureau_id: edu_bureau_id == 0 ? '' : edu_bureau_id,
          edu_school_id: edu_school_id + '',
          edu_grade_id: edu_grade_id,
          // edu_class_id,
          edu_class_id: user_class_id ? user_class_id.split(',').map(item => Number(item)) : [],
          subject_id: subject,
          end_time,
          is_detailed,
          is_admin,
          is_test_paper,
          role: roles ? roles.split(',').map(item => item - 0) : [],
          edu_user_id
        }

        console.log('subject_id ', typeof subject_id)
        this.$refs.FormRef.form = form
        this.$refs.FormRef.text = '编辑'
        this.$refs.FormRef.getList()
        this.$refs.FormRef.getClassList()
        this.$refs.FormRef.dialogVisible = true
      })
      return
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    async exportExcel () {
      await this.$http({
        url: '/api/v2/bureau/export_teacher',
        method: 'get',
        responseType: "blob",
        params: {
          keyword: this.keyword
        }
      }).then(res => {
        console.log('res', res);
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `教师列表.xlsx`
        a.click()
      })
    },
    tongbu () {
      this.$http({
        url: 'api/v2/bureau/sync',
        method: 'get'
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '同步成功!',
          type: 'success'
        });
        this.search()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
